.news-grid-wrapper{
    grid-template-columns: 1fr;
    display: grid;
    padding: 20px;
    row-gap: 30px;
}
.post{
    background-color: white;
    padding: 15px;
    padding-bottom: 25px;
    border-radius: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}


.post-image{
    height: 220px;
}
.title{
    font-family: "Noto Sans", sans-serif;
    font-weight: 700;
    font-size: 38px;
    color: black;
}

.date{
    font-family: "Noto Sans", sans-serif;
    color: rgb(150, 150, 150);
}

.post-button + .post-button{
    margin-top: 30px;
}

.send-input {
    font-family: "Noto Sans", sans-serif;
    padding: 10px;
    background-color: #e49333;
    font-weight: 700;
    border: 2px solid #f0a54a;
    border-radius: 10px;
    transition: all 300ms;
    color: white;
    cursor: pointer;
}
  
.send-input:hover {
    background-color: #e43339;
    border: 2px solid #e7484d;
}
.page-change-container{
    display: flex;
    justify-content: center;
}
.page-change{
    font-family: "Noto Sans", sans-serif;
    padding: 4px;
    background-color: #e49333;
    font-weight: 700;
    border: 2px solid #f0a54a;
    border-radius: 10px;
    transition: all 300ms;
    color: white;
    cursor: pointer;
}

.arrow-image{
    width: 24px;
    height: 24px;
    color: white;
    filter: invert(100%) sepia(0%) saturate(7469%) hue-rotate(11deg) brightness(109%) contrast(107%);
}

.page-number{
    color: black;
    font-size: 24px;
    font-weight: 900;
    padding: 5px 20px;
}
.page-change:disabled{
    background-color: #e0b178;
    cursor: not-allowed;
}

.post-image{
    border-radius: 10px;
}