.dot {
  background-color: rgba(255, 255, 255, 0.5);
  border: 4px solid #636363;
  height: 24px;
  width: 24px;
  display: block;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.3s all;
}
.active_dot,
.dot:hover {
  background-color: rgb(245, 205, 156);
  border: 4px solid #e49333;
}

.PhotosWrapper {
  margin-top: 20px;
}

.Photosliderimages {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 46vh;
}

.Photoslidertext {
  display: flex;
  justify-content: center;
}
.slider_back {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}

/* Animation */
@keyframes slideFromLeft {
  0% {
    transform: translateX(-100vw);
    opacity: 0;
  }
  100% {
    left: 50%;
    transform: translate(-50%, 0);
    opacity: 1;
  }
}

@keyframes slideToRight {
  0% {
    left: 50%;
    transform: translate(-50%, 0);
    opacity: 1;
  }
  100% {
    transform: translateX(100vw);
    opacity: 0;
  }
}

.PhotosWrapper {
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: calc(46vh + 30px);
}

.prevPhoto {
  position: absolute;
  top: 0;
  left: -50%;

  animation: 1s ease-out 0s 1 slideToRight;
  opacity: 0;
}

.currentPhoto {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  animation: 800ms ease-out 0s 1 slideFromLeft;
  opacity: 1;
  margin: 0 auto;
}

@media (max-width: 600px) {
  .slider_back {
    width: 100%;
  }
}

.offers-title {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
