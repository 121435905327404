* {
  padding: 0;
  margin: 0;
  list-style: none;
}
body {
  background-color: #f3f1ed;
  overflow-x: hidden;
  font-family: "Noto Sans", sans-serif;
}
.site-wrapper {
  min-height: 100vh;
  position: relative;
}
a {
  color: #d9d9d9;
  text-decoration: none;
}
nav,
footer {
  height: 90px;
  color: #d9d9d9;
  background: #242424;
  font-family: "Roboto Mono", monospace;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
}
footer {
  text-align: left;
  height: 220px;
  position: absolute;
  width: 100%;
  bottom: 0;
}
nav li {
  border-bottom: 4px solid transparent;
  position: relative;
}
nav li:hover {
  border-bottom: 4px solid;
  border-image-slice: 1;
  border-width: 4px;
  border-image-source: linear-gradient(
    90.24deg,
    #e43339 -9.45%,
    #f6a440 98.82%
  );
}
nav li + li {
  margin-left: 32px;
}
footer li + li {
  margin-top: 5px;
}
.pageWidth {
  margin: 0 auto;
  width: 66vw;
}
.content {
  padding-bottom: 230px;
}
.cage {
  margin: 0 auto;
  width: 66vw;
  min-height: calc(100vh - 220px);
  background-color: white;
}
.flexblock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  height: 67px;
  width: 100px;
}
footer li.flexblock {
  justify-content: start;
}
.footer__location {
  margin-right: 12px;
  width: 16.8px;
  height: 21px;
}
.footer__phone {
  margin-right: 14px;
  margin-left: 2px;
  width: 12px;
  height: 20px;
}
.footer__telephone {
  margin-right: 8px;
  margin-left: 2px;
  width: 18px;
  height: 18px;
}
.footer__contact {
  width: 20px;
  height: 16.01px;
}

.text-input {
  outline: none !important;
  font-size: 20px;
  width: 100%;
  padding: 10px;
  height: 47px;
  /* Stack overflow  */
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;

  border: 2px solid black;
  border-radius: 6px;
  transition: all 300ms;
}

.text-input:hover {
  border: double 2px transparent;
  border-radius: 6px;
  background-image: linear-gradient(white, white),
    linear-gradient(60deg, #e43339 -16.94%, #d8595d 110.66%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.text-input:focus {
  border: double 3px transparent;
  border-radius: 6px;
  background-image: linear-gradient(white, white),
    linear-gradient(90.24deg, #e49333 -9.45%, #fec27a 98.82%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  transition: all 150ms;
}

textarea {
  resize: vertical;
  min-height: 250px;
}

.send-input {
  padding: 10px;
  font-size: 20px;
  background-color: #e49333;
  font-weight: 700;
  border: 2px solid #f0a54a;
  border-radius: 10px;
  transition: all 300ms;
  color: white;
  cursor: pointer;
}

.send-input:hover {
  background-color: #e43339;
  border: 2px solid #e7484d;
}

.text-input::file-selector-button {
  padding: 10px;
  font-size: 20px;
  background-color: #e49333;
  font-weight: 700;
  border: 2px solid #f0a54a;
  border-radius: 10px;
  transition: all 300ms;
  color: white;
  cursor: pointer;
}

.text-input::file-selector-button:hover {
  background-color: #e43339;
  border: 2px solid #e7484d;
}

.file-input {
  padding-bottom: 60px;
  border: 0px solid black !important;
  padding-left: 0px;
  padding-right: 0px;
}

@media (max-width: 1400px) {
  .cage {
    width: 100%;
  }
  .pageWidth {
    width: 100%;
  }
  nav {
    padding: 0px 60px;
  }
}
/* Navbar responsive */
.nav-toogle {
  display: none;
}
@media (max-width: 870px) {
  .center-button {
    display: block;
    width: 400px;
    text-align: center;
    margin: 0 auto;
  }
  .nav-link,
  .logo-desktop {
    width: 0;
    height: 0;
    overflow: hidden;
    padding: 0;
    transition: 300ms all;
  }
  .nav-toogle {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  nav {
    height: auto;
    padding: 0px;
    transition: 300ms all;
  }
  nav ul {
    padding: 0px;
    width: 100%;
    height: 111px;
    flex-direction: column;
    transition: 300ms all;
  }

  nav ul.active {
    height: auto;
  }

  nav ul li {
    display: flex;
    width: calc(100% - 40px);
    padding: 20px;
    margin: 0;
  }

  nav li:hover {
    border-bottom: 4px solid;
    border-image-slice: 1;
    border-width: 4px;
    border-image-source: linear-gradient(
      90.24deg,
      #242424 -9.45%,
      #242424 98.82%
    );
  }
  .burger {
    cursor: pointer;
    width: 42px;
    height: 42px;
    filter: invert(100%) sepia(0%) saturate(7469%) hue-rotate(11deg)
      brightness(109%) contrast(107%);
  }

  nav .nav-link.active {
    display: block;
    border-top: 2px solid;
    border-bottom: 0px solid !important;
    border-image-slice: 1;
    border-width: 2px;
    border-image-source: linear-gradient(
      90.24deg,
      #e43339 -9.45%,
      #f6a440 98.82%
    );

    width: 100%;
    height: auto;
    overflow: hidden;
    padding: 20px;
  }

  nav li:hover .active {
    border-top: 2px solid;
    border-image-slice: 1;
    border-width: 2px;
    border-image-source: linear-gradient(
      90.24deg,
      #e43339 -9.45%,
      #f6a440 98.82%
    );
  }
}
.error-input {
  border: 1px solid #e43339;
}
