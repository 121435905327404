.login-wrapper{
    margin: 20px 20px 190px;
    padding: 20px;
    width: calc(100% -40px);
    background: white;
    border-radius: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: grid;
    grid-template-columns: 1fr;
}
.login-wrapper input{
    margin-bottom: 15px;
}
.login-wrapper h2{
    color: #535353;
    margin-bottom: 10px;
}