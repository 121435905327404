.home-grid-wrapper {
  display: grid;
  align-items: center;
  grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr);
  row-gap: 12px;
  column-gap: 150px;
  background-color: white;
  padding: 10px;
}

.spacer {
  grid-column-start: 1;
  grid-column-end: span 2;
  height: 4px;
  background: linear-gradient(90.24deg, #e49333 -9.45%, #fec27a 98.82%);
}
.grid-image {
  display: block;
  margin: 0 auto;
  max-height: 250px;
  width: auto;
  max-width: 100%;
  border-radius: 20px;
}
.grid-text {
  font-family: "Noto Sans", sans-serif;
  font-size: 24px;
  max-width: 700px;
  padding: 20px;
}

@media (max-width: 1400px){
  .home-grid-wrapper {
    row-gap: 50px !important;
    column-gap: 50px !important;
  } 
}

@media (max-width: 940px){
  .home-grid-wrapper{
    grid-template-columns: minmax(100px, 1fr) !important;
  }
  .spacer{
    grid-column-end: span 1 !important;
  }
  #force-second{
    grid-row-start: 5;
  }
  #force-first{
    grid-row-start: 4;
  }
}

