.questions-grid-wrapper{
    display: grid;
    row-gap: 30px;
    grid-template-columns: 1fr !important;
    padding: 20px;
}

.section-title{
    width: auto;
    margin: 0 auto;
}
.no-grid-spacer{
    grid-column-start: 1;
    grid-column-end: span 1;
    height: 4px;
    background: linear-gradient(90.24deg, #e49333 -9.45%, #fec27a 98.82%);
}

.input-spacing{
    margin-bottom: 30px;
}

.question-mail{
    padding-bottom: 20px;
}
.question-name{
    padding-bottom: 20px;
}
.question-topic{
    padding-bottom: 20px;
}
.question-text{
    padding-bottom: 20px;
}