.grid-wrapper {
  display: grid;
  align-items: center;
  grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr);
  row-gap: 12px;
  column-gap: 150px;
  background-color: white;
  padding: 10px;
}

.spacer {
  grid-column-start: 1;
  grid-column-end: span 2;
  height: 4px;
  background: linear-gradient(90.24deg, #e49333 -9.45%, #fec27a 98.82%);
}
.grid-image {
  display: block;
  margin: 0 auto;
  max-height: 250px;
  width: auto;
  max-width: 100%;
  border-radius: 20px;
}
.grid-text {
  font-family: "Noto Sans", sans-serif;
  font-size: 24px;
  max-width: 700px;
  padding: 20px;
}
.headers{
  font-family: "Noto Sans", sans-serif;
}
.date{
  font-family: "Noto Sans", sans-serif;
  color: rgb(150, 150, 150);
}
.title{
  font-family: "Noto Sans", sans-serif;
  font-weight: 700;
  font-size: 46px;
  color: black;
}
.margin{
  padding: 20px;
}
.imger{
  height: 300px;
  border-radius: 20px;
}

.post-text{
  font-family: "Noto Sans", sans-serif;
  font-size: 12;
  margin-top: 30px;
}

.post-edit-button{
  margin-top: 20px;
}



@media (max-width: 1100px){
  .flex-responsive {
    flex-direction: column;
    margin-bottom: 30px;
  }
}

