.contact-grid-wrapper {
  display: grid;
  align-items: center;
  grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr);
  row-gap: 90px;
  column-gap: 150px;
  background-color: white;
  padding: 40px;
  font-family: "Noto Sans", sans-serif;
}
ul {
  font-size: 20px;
  font-weight: 700;
}
.map {
  width: 100%;
  aspect-ratio: 1 / 1;
  outline: none;
}
.form {
  grid-column-start: 1;
  grid-column-end: span 2;
}
.input-box {
  margin: 20px 0;
  width: 100%;
}
h3 {
  padding-bottom: 6px;
}

@media (max-width: 820px){
  .contact-grid-wrapper{
    grid-template-columns: minmax(100px, 1fr) !important;
  }
  .form{
    grid-column-start: 1;
    grid-column-end: span 1;
  }
  .google-maps{
    min-width: 80vw;
    min-height: auto;
    margin: 0 auto;
  }
}